/*
 * @Description: 菜单
 * @Autor: elvin
 * @Date: 2021-04-03 14:31:14
 * @LastEditors: elvin
 * @LastEditTime: 2022-07-24 21:35:37
 */
import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { Menu, Button } from 'antd';
import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
} from '@ant-design/icons';
import menuConfig from './config'

const { SubMenu } = Menu;

const MenuComponent = () => {
    const [collapsed, setCollapsed] = useState()

    const clickMenu = (e)=>{

    }

    const path = window.location.pathname;
    // 默认选中首个菜单
    let selectedKeys= menuConfig[0]?.key;

    const item = menuConfig.find(item=>path.includes(item.key))
    if(item){
        selectedKeys = item.key;
    }

    return (
        <div
            style={{width: collapsed?'80px':'200px'}}
            className="main__menu"
        >
            <Menu
                defaultSelectedKeys={['1']}
                defaultOpenKeys={['sub1']}
                selectedKeys={[selectedKeys]}
                mode="inline"
                theme="dark"
                inlineCollapsed={collapsed}
                style={{
                    position: 'relative',
                    height: '100%'
                }}
                onClick={clickMenu}
            >
                {
                    menuConfig.map(item=>{
                        if(item.children){
                            return (
                                <SubMenu key={item.key} icon={<item.icon />} title={item.name}>
                                    {
                                        item.children.map(sub=>{
                                            return (
                                                <Menu.Item key={sub.key}>
                                                    <Link to={sub.key}>{sub.name}</Link>
                                                </Menu.Item>
                                            )
                                        })
                                    }
                                </SubMenu>
                            )
                        }
                        return (
                            <Menu.Item key={item.key} icon={<item.icon />}>
                                <Link to={item.key}>{item.name}</Link>
                            </Menu.Item>
                        )
                    })
                }
            </Menu>
                <Button
                    type="primary"
                    onClick={() => { setCollapsed(!collapsed) }}
                    style={{
                        position: 'absolute',
                        bottom: 0,
                        width: '100%',
                    }}
                >
                    {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined)}
                </Button>
        </div>
    )

}

export default MenuComponent;