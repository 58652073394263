/*
 * @Description:
 * @Autor: elvin
 * @Date: 2020-05-10 20:09:20
 * @LastEditors: elvin
 * @LastEditTime: 2021-10-24 20:38:27
 */
const localhost = 'http://localhost:8989';
// const ipHost = 'http://192.168.0.110:8989';
// const ipHost = 'http://10.250.8.53:8989';

const productBaseURL = 'http://kj.elvenelvin.com';

const proxyTargetMap = {
    prod: productBaseURL,
    local: localhost,
}
// const proxyTargetMap = {
//     prod: '/',
//     randy: '/randy',
//     peter: '/peter'
// }
const API_TYPE = process.env.API_TYPE?process.env.API_TYPE:'local'
const baseUrl = process.env.NODE_ENV === 'production' ? proxyTargetMap.prod : proxyTargetMap[API_TYPE]
export default baseUrl