/*
 * @Description:
 * @Autor: elvin
 * @Date: 2021-04-03 15:08:00
 * @LastEditors: elvin
 * @LastEditTime: 2022-07-24 22:18:08
 */
import React, { useEffect, useState } from 'react';
import {Dropdown,Menu,Button} from 'antd';
import { getLocal } from '../../utils';
import { UserTypeEnum } from '../../pages/_Config/config';

const Header = ()=>{
    const [userName, setName] = useState("");
    const userRoleCode = getLocal("userRoleCode")

    useEffect(()=>{
        setName(getLocal("userName"));
    },[])

    return (
        <div className="main__header">
            <span>会捷WMS管理系统</span>
            <span style={{ color: '#2196f3'}}><b>【{Object.values(UserTypeEnum).find(item=>item.key===userRoleCode)?.value}】</b></span>
            <Dropdown
                overlay={(
                    <Menu>
                        <Menu.Item>
                            <Button type="link" danger onClick={()=>{
                                localStorage.clear();
                                window.location.href="/login";
                            }}>退出登录</Button>
                        </Menu.Item>
                    </Menu>
                )}
            >
                <Button type="link">{userName||"用户"}</Button>
            </Dropdown>
        </div>
    )
}

export default Header;