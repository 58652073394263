import React from "react"
import { Menu, Icon, Breadcrumb,Select } from "antd"
import { Link } from "react-router-dom"
import request from './request'

const Option = Select.Option
const { SubMenu } = Menu
const MenuItem = Menu.Item

//获取侧边栏Item
export const getMenuItem = list => {
    return list.map((item, index) => {
        if (item.children && item.children.length > 0) {
            return (
                <SubMenu
                    key={item.path}
                    title={
                        <span>
                            <Icon type={item.icon} />
                            <span>{item.name}</span>
                        </span>
                    }
                >
                    {getMenuItem(item.children)}
                </SubMenu>
            )
        } else {
            return (
                <MenuItem key={item.path}>
                    <Link to={item.path}>
                        <Icon type={item.icon} />
                        <span>{item.name}</span>
                    </Link>
                </MenuItem>
            )
        }
    })
}

//获取面包屑Item
export const getBreadItem = (list) => {
    const arr = [];
    function getItem(allList){
        allList.forEach((item,index) => {
            if(item.children && item.children.length>0){
                arr.push(
                    <Breadcrumb.Item key={index}>
                        <Link  to={item.redirect}>
                            {item.name}
                        </Link>
                    </Breadcrumb.Item>
                )
                getItem(item.children)

            }else{
                arr.push(
                    <Breadcrumb.Item key={index}>
                        <Link  to={item.path}>
                            {item.name}
                        </Link>
                    </Breadcrumb.Item>
                )
            }

        })
    }
    getItem(list)
    return arr
}

//左侧栏默认展开项
export const filterRoutes = pathname => {
    let pathSnippets = pathname.split('/').filter(path => path)
    let paths = pathSnippets.map((path, index) => `/${pathSnippets.slice(0, index + 1).join('/')}`)
    paths.splice(0,1)
    return paths
}

//获取options
export const getOptionsList = data => {
    if(!(data instanceof Array)){
        return []
    };
    return data.map((item,index)=>{
        return <Option key={item.id} value={item.value}>{item.label}</Option>
    })
}

//获取分页关键内容
export const pagination = (data,callback) =>{
    return {
        current:data.page,
        pageSize:data.pageSize,
        total:data.total,
        showQuickJumper:false,
        onChange:(current)=>{
            callback(current)
        },
        showTotal:()=>{
            return `共${data.total}条`
        }
    }
}

//初始列表
export const getList = (_this,options) => {
    request(options)
    .then(res =>{
        if(res && res.data && res.data.data){
            let dataSource = res.data.data.map((item, index) => {
                item.key = index;
                return item;
            });
            _this.setState({
                dataSource,
                pagination: pagination(res.data, (current) => {
                    _this.params.page = current;
                    _this.requestList();
                })
            })
        }
    })
}

//设置勾选后的内容
/**
 * @param {*选中行的索引} selectedRowKeys Array
 * @param {*选中行对象} selectedItem Array
 */
export const updateSelectedItem = (selectedRowKeys,selectedRows,that) => {
    const rowSelection = {
        selectedRowKeys,selectedRows
    }
    that.setState({
        rowSelection
    })
}

//获取localstorage
export const getLocal = item => {
    return localStorage.getItem(item)
}

//设置localstorage
export const setLocal = (key,value) => {
    localStorage.setItem(key,value)
}

//移除
export const removeLocal = (key) => {
    localStorage.removeItem(key)
}

//时间戳转换
export const formateDate = time => {
    if(!time)return '';
    let date = new Date(time);
    return date.getFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate()+' '+date.getHours()+':'+date.getMinutes()+':'+date.getSeconds();
}

// YYYY-MM-DD HH:mm
export const DetailFormat = "YYYY-MM-DD HH:mm";

// YYYY-MM-DD
export const DateFormat = "YYYY-MM-DD";

// YYYY-MM
export const MonthFormat = "YYYY-MM";

/**
 * 分转元
 * @param {*} fen
 * @returns
 */
export const regFenToYuan = (fen) =>{
    var num = fen;
    num=fen*0.01;
    num+='';
    var reg = num.indexOf('.') >-1 ? /(\d{1,3})(?=(?:\d{3})+\.)/g : /(\d{1,3})(?=(?:\d{3})+$)/g;
    num=num.replace(reg,'$1');
    num = toDecimal2(num)
    return num
};



/**
 * 元转分
 * @param {*} yuan 转换金额
 * @returns
 */
export const regYuanToFen = (yuan) =>{
    return Math.round(yuan*100);
}

/**
 * 判断是否最多两位小数
 * @param {*} inputNumber
 * @returns
 */
export const checkTwoPointNum = (inputNumber) => {
    var partten = /^-?\d+\.?\d{0,2}$/;
    return partten.test(inputNumber)
};

/**
 * 强制保留2位小数
 * @param {*} x
 * @returns
 */
export const toDecimal2 = (x) => {
  var f = parseFloat(x);
  if (isNaN(f)) {
    return false;
  }
  var h = Math.round(x * 100) / 100;
  var s = h.toString();
  var rs = s.indexOf('.');
  if (rs < 0) {
    rs = s.length;
    s += '.';
  }
  while (s.length <= rs + 2) {
    s += '0';
  }
  return s;
}

/**
 * params conect
 */
export const paramsConcat = (url,paramsObejct)=>{
    let param = ""
    Object.keys(paramsObejct).forEach(item=>{
        if(paramsObejct[item]!==undefined){
            param += `&${item}=${paramsObejct[item]}`
        }
    })
    if(url.indexOf('?')>0){
        return `${url}&${param.replace("&","")}`;
    }
    return `${url}?${param.replace("&","")}`;
}