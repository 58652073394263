/*
 * @Description:
 * @Autor: elvin
 * @Date: 2020-05-10 20:09:20
 * @LastEditors: elvin
 * @LastEditTime: 2021-08-15 21:44:15
 */
import axios from 'axios'
import baseURL from './baseUrl'
import { getLocal } from '../utils'
import { message } from 'antd';

const whiteList = ['/v1/api/user/loginAppUser']
//创建axios实例
const service = axios.create({
    baseURL: baseURL, // api的base_url
    timeout: 200000, // 请求超时时间
    withCredentials: true // 选项表明了是否是跨域请求
})
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.get['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';

service.interceptors.request.use(config => {
    // 请求头添加token
    const Authorization = getLocal('token');

    if(!whiteList.includes(config.url)&& !Authorization){
        window.location.href='/login';
        return;
    }

    if (Authorization) {
        config.headers.Authorization = `${Authorization}`
    }

    if(config.method==='post'){
        config.data = config.params
        delete config.params;
    }

    return config;
}, err => {
    message.error('请求失败')
    return Promise.reject(err)
})



//拦截响应
service.interceptors.response.use(config => {
    if(config.data && config.data.loading !==false){
        let loading
        loading = document.getElementById('ajaxLoading')
        loading.style.display = 'none'
    }
    return config;
}, err => {
    message.error(err.message);
    return Promise.reject(err)
})



// respone拦截器
service.interceptors.response.use(
    response => {
        const res = response.data

        if (res.code !== '0') {
            message.error(res.message)
            return Promise.reject('error')
        } else {
            return response.data
        }
    },
    error => {
        const { status } = error.response||{}
        switch (status) {
            case 401:
            break;
            default:
            break;
        }
        return Promise.reject(error)
    }
)
export default service
