/*
 * @Description:
 * @Autor: elvin
 * @Date: 2020-05-10 20:09:20
 * @LastEditors: elvin
 * @LastEditTime: 2021-11-21 20:10:15
 */
import React from 'react';
import { Breadcrumb } from 'antd';
import AsyncComponent from './utils/asyncComponent';
import { Link } from 'react-router-dom';

const NotFound = AsyncComponent(()=>import('./pages/NotFound'))

const routes = [
    {
        path: '/',
        exact: true,
        component: AsyncComponent(()=>import('./pages/Product/Manage')),
        requiresAuth: false,
        breadcrumb: <Breadcrumb>
            <Breadcrumb.Item>
            <Link href="/">物料系统</Link>
            </Breadcrumb.Item>
        </Breadcrumb>,
    },
    // 登录
    {
        path: '/login',
        exact: true,
        component: AsyncComponent(()=>import('./pages/Login')),
        requiresAuth: false,
        breadcrumb: <Breadcrumb>
            <Breadcrumb.Item>登录</Breadcrumb.Item>
        </Breadcrumb>,
    },
    // 物料管理
    {
        path: '/product/manage',
        exact: true,
        component: AsyncComponent(()=>import('./pages/Product/Manage')),
        requiresAuth: false,
        breadcrumb: <Breadcrumb>
            <Breadcrumb.Item>
            <Link href="/">物料系统</Link>
            </Breadcrumb.Item>
        </Breadcrumb>,
    },
    // 物料出入库明细
    {
        path: '/product/manage/:pcode',
        exact: true,
        component: AsyncComponent(()=>import('./pages/Product/Detail')),
        requiresAuth: false,
        breadcrumb: <Breadcrumb>
            <Breadcrumb.Item>
                <Link to="/">物料系统</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>物料出入库记录</Breadcrumb.Item>
        </Breadcrumb>,
    },
    // 入库记录
    {
        path: '/product/addinrecord',
        exact: true,
        component: AsyncComponent(()=>import('./pages/Product/AddinRecord')),
        requiresAuth: false,
        breadcrumb: <Breadcrumb>
            <Breadcrumb.Item>
            <Link href="/">物料系统</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>物料入库记录</Breadcrumb.Item>
        </Breadcrumb>,
    },
    // 出库记录
    {
        path: '/product/checkoutrecord',
        exact: true,
        component: AsyncComponent(()=>import('./pages/Product/CheckoutRecord')),
        requiresAuth: false,
        breadcrumb: <Breadcrumb>
            <Breadcrumb.Item>
            <Link href="/">物料系统</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>物料出库记录</Breadcrumb.Item>
        </Breadcrumb>,
    },
    // 库存商品明细账
    {
        path: '/finance/productstore',
        exact: true,
        component: AsyncComponent(()=>import('./pages/Finance/ProductStore')),
        requiresAuth: false,
        breadcrumb: <Breadcrumb>
            <Breadcrumb.Item>
            <Link href="/">物料系统</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>库存商品明细账</Breadcrumb.Item>
        </Breadcrumb>,
    },
    // 主营业务成本明细账
    {
        path: '/finance/mainbusinesscost',
        exact: true,
        component: AsyncComponent(()=>import('./pages/Finance/MainBusinessCost')),
        requiresAuth: false,
        breadcrumb: <Breadcrumb>
            <Breadcrumb.Item>
            <Link href="/">物料系统</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>主营业务成本明细账</Breadcrumb.Item>
        </Breadcrumb>,
    },
    // 原材料明细账
    {
        path: '/finance/rawmaterial',
        exact: true,
        component: AsyncComponent(()=>import('./pages/Finance/RawMaterial')),
        requiresAuth: false,
        breadcrumb: <Breadcrumb>
            <Breadcrumb.Item>
            <Link href="/">物料系统</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>原材料明细账</Breadcrumb.Item>
        </Breadcrumb>,
    },
    // 库存警报
    {
        path: '/product/stockalert',
        exact: true,
        component: AsyncComponent(()=>import('./pages/Product/StockAlert')),
        requiresAuth: false,
        breadcrumb: <Breadcrumb>
            <Breadcrumb.Item>
            <Link href="/">物料系统</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>库存警报</Breadcrumb.Item>
        </Breadcrumb>,
    },
    // 企业管理
    {
        path: '/account/company',
        exact: true,
        component: AsyncComponent(()=>import('./pages/Account/Company')),
        requiresAuth: false,
        breadcrumb: <Breadcrumb>
            <Breadcrumb.Item>
            <Link href="/">物料系统</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>企业管理</Breadcrumb.Item>
        </Breadcrumb>,
    },
    // 账户列表
    {
        path: '/account/list',
        exact: true,
        component: AsyncComponent(()=>import('./pages/Account/List')),
        requiresAuth: false,
        breadcrumb: <Breadcrumb>
            <Breadcrumb.Item>
            <Link href="/">物料系统</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>账户列表</Breadcrumb.Item>
        </Breadcrumb>,
    },
    {
        path: '*',
        component: NotFound,
        requiresAuth: false,
        breadcrumb: <Breadcrumb>
            <Breadcrumb.Item>
            <Link href="/">物料系统</Link>
            </Breadcrumb.Item>
        </Breadcrumb>,
    }
]

export default routes