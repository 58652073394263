/*
 * @Description:
 * @Autor: elvin
 * @Date: 2021-04-17 22:25:37
 * @LastEditors: elvin
 * @LastEditTime: 2022-07-24 23:07:56
 */
/**
 * 入库key集合
 */
export const AddinTypes = [1,2,3];

/**
 * 出库key集合
 */
 export const CheckoutTypes = [10,11,12];

/**
 * 出入库类型
 */
export const StoreTypeEnum = {
    /**
     * 采购入库
     */
    BOUGHT: {
        key: 1,
        value: '采购入库'
    },
    /**
     * 盘盈入库
     */
    PROFIT: {
        key: 2,
        value: '盘盈入库'
    },
    /**
     * 销售退货
     */
    REFUND: {
        key: 3,
        value: '销售退货'
    },

    // 生产出库
    PRODUCE: {
        key: 10,
        value: '生产出库'
    },
    // 盘损出库
    DEFICIT: {
        key: 11,
        value: '盘损出库'
    },
    // 销售出库
    SALE: {
        key: 12,
        value: '销售出库'
    },
}

/**
 * 用户类型
 */
export const UserTypeEnum = {
    /** 超级管理员 */
    SUPER: {
        key: '1000',
        value: '超管'
    },
    /** 管理员 */
    ADMIN: {
        key: '1111',
        value: '管理员'
    },
    /** 会计 */
    EMPLOYEE: {
        key: '1112',
        value: '会计'
    },
    /** 库管 */
    PRODUCT_KEEPER: {
        key: "1113",
        value: "库管",
    },
    /** 访客 */
    PRODUCT_VISITOR: {
        key: "1114",
        value: "访客",
    },
}