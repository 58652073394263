/*
 * @Description:
 * @Autor: elvin
 * @Date: 2020-05-10 20:09:20
 * @LastEditors: elvin
 * @LastEditTime: 2021-04-11 20:49:54
 */
import React from 'react';
import ReactDOM from 'react-dom';
import { ConfigProvider } from 'antd';
import App from './App';
import * as serviceWorker from './serviceWorker';
import zhCN from 'antd/lib/locale/zh_CN';
import {BrowserRouter, Route} from 'react-router-dom'
import './stylus/index.less'

const Main = () =>{
    return (
        <ConfigProvider locale={zhCN}>
            <BrowserRouter basename='/'>
                <Route path={`/`} component={App}></Route>
            </BrowserRouter>
        </ConfigProvider>
    )
}

ReactDOM.render(<Main />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
