/*
 * @Description:
 * @Autor: elvin
 * @Date: 2021-04-03 14:57:31
 * @LastEditors: elvin
 * @LastEditTime: 2022-07-24 21:36:07
 */
import {
    FileDoneOutlined,
    InteractionOutlined,
} from '@ant-design/icons';
import { getLocal } from '../../utils';

const baseConfig = [
    {
        key: '/product/manage',
        name: '物料管理',
        icon: FileDoneOutlined,
        children: false
    },
    {
        key: '/product/addinRecord',
        name: '物料入库记录',
        icon: InteractionOutlined,
        children: false
    },
    {
        key: '/product/checkoutrecord',
        name: '物料出库记录',
        icon: InteractionOutlined,
        children: false
    }
];

const otherConfig = [
    {
        key: '/finance/productstore',
        name: '库存商品明细账',
        icon: FileDoneOutlined,
        children: false
    },
    {
        key: '/finance/mainbusinesscost',
        name: '主营业务成本明细账',
        icon: FileDoneOutlined,
        children: false
    },
    {
        key: '/finance/rawmaterial',
        name: '原材料明细账',
        icon: FileDoneOutlined,
        children: false
    },
    {
        key: '/product/stockalert',
        name: '库存警报',
        icon: InteractionOutlined,
        children: false
    },
]

const accountConfig = [
    {
        key: '/Account/List',
        name: '账户管理',
        icon: InteractionOutlined,
        children: false
    }
]

const companyConfig = [
    {
        key: '/Account/Company',
        name: '企业管理',
        icon: InteractionOutlined,
        children: false
    }
]
const userRoleCode = getLocal("userRoleCode");

const userMap = {
    '1000': [
        ...baseConfig,
        ...otherConfig,
        ...accountConfig,
        ...companyConfig
    ],
    '1111': [
        ...baseConfig,
        ...otherConfig,
        ...accountConfig,
    ],
    '1112': [
        ...baseConfig,
        ...otherConfig,
    ],
    '1113': [
        ...baseConfig,
    ],
    '1114': [
        ...baseConfig,
    ],
}

export default userMap[userRoleCode] || baseConfig;
